<template>
  <!--  入库审核-->
  <div class="ReviewInventoryIn">
    <el-descriptions :column="3">
      <el-descriptions-item label="商品SKU">{{ goodsData.sku }}</el-descriptions-item>
      <el-descriptions-item label="货品条码">{{ goodsData.barcode }}</el-descriptions-item>
      <el-descriptions-item label="货品类别"></el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions :column="3">
      <el-descriptions-item label="货品名称">{{ goodsData.cname }}</el-descriptions-item>
      <el-descriptions-item label="货品成本价"></el-descriptions-item>
      <el-descriptions-item label="货品零售价">{{ goodsData.price }}</el-descriptions-item>
    </el-descriptions>
    <div class="h500">
      <el-table ref="multipleTable" :data="orderDetailOutList" height="450">
        <el-table-column label="序号" align="center" width="50" type="index" />
        <el-table-column label="门店名称" align="center" width="150" prop="cstore" />
        <el-table-column label="SKU" align="center" width="70" prop="cremark" />
        <el-table-column label="库位号" align="center" width="100" prop="cwarehouse" />
        <el-table-column label="库存" align="center" width="50" prop="ntotal" />
        <el-table-column align="center" label="更新时间">
          <template slot-scope="scope">
            <div>{{ scope.row.tupdate | time }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination :current-page="queryPara.currentPage" :page-size="queryPara.pageSize" :page-sizes="pageSizes" :total="total" layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
      </div>
    </div>
    <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
      <div>
        
      </div>
      <div class="flex">
        <el-button plain @click="handleExport">导出Excel</el-button>
        <el-button @click="handleClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment/moment";
import { exportSurplus } from "@/api/goods/goods";

export default {
  name: "ReviewInventoryIn",
  filters: {
    time(time) {
      if (time !== "" && time !== null && time !== undefined) {
        return moment(time).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
  },
  props: {
    goodsData: {
      type: [String, Object, Array], // 根据需要指定数据类型
    },
  },
  data() {
    return {
      queryPara: {
        currentPage: 1,
        pageSize: 10,
        goodsId: null
      },
      total: 0,
      orderDetailOutList: [],
      loading: false,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  created() {
    this.queryPara.goodsId = this.goodsData.goodsId;
    this.queryPageGoods();
  },
  methods: {
    queryPageGoods() {
      this.loading = true
      let url = `${this.baseUrls.goods}/queryPageSurplusList`;
      let params = this.queryPara;
      this.topsAjax.get(url, params).then((res) => {
        this.loading = false
        this.total = res.total;
        this.orderDetailOutList = res.list;
      }).catch(() => {
        this.loading = false
      });
    },
    handleCurrentChange(val) {
      this.queryPara.currentPage = val;
      this.queryPageGoods();
    },
    handleSizeChange(val) {
      this.queryPara.pageSize = val;
      this.queryPageGoods();
    },
    handleClose() {
      this.$emit("handleClose");
    },
    handleExport() {
      exportSurplus({goodsId: this.goodsData.goodsId })
    }
  },
};
</script>

<style lang="scss">
.ReviewInventoryIn {
  height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .el-divider--horizontal {
    margin: 8px 0;
  }
}
</style>
