var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shopData box-shadow" },
    [
      _c(
        "div",
        { staticClass: "shopData__top" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "今日数据", name: "1" } }),
              _c("el-tab-pane", { attrs: { label: "历史数据", name: "2" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.tabs === "2"
        ? _c(
            "dc-search-panel",
            {
              attrs: { params: _vm.queryPara },
              on: { search: _vm.doSearch, reset: _vm.doReset },
            },
            [
              _c("dc-date-picker", {
                attrs: {
                  field: "startTime",
                  label: "开始时间",
                  "value-format": "yyyy-MM-dd",
                },
              }),
              _c("dc-date-picker", {
                attrs: {
                  field: "endTime",
                  label: "结束时间",
                  "value-format": "yyyy-MM-dd",
                },
              }),
              _c(
                "el-button",
                {
                  attrs: { slot: "btns", size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.getMonth(1)
                    },
                  },
                  slot: "btns",
                },
                [_vm._v("本月")]
              ),
              _c(
                "el-button",
                {
                  attrs: { slot: "btns", size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.getMonth(2)
                    },
                  },
                  slot: "btns",
                },
                [_vm._v("上个月")]
              ),
              _c(
                "el-button",
                {
                  attrs: { slot: "btns", size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.getMonth(3)
                    },
                  },
                  slot: "btns",
                },
                [_vm._v("三个月")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "shopData__body" },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.storeCountList,
                border: "",
                "show-summary": "",
                "summary-method": _vm.getSummaries,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "",
                  prop: "cName",
                  label: "门店",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "nValid", label: "有效订单" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "nTurnover", label: "营业额" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "nMerchantRevenue",
                  label: "商家实收",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "nUnitPrice", label: "客单价" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }