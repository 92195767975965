var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsAnalyze box-shadow" },
    [
      _c(
        "div",
        { staticClass: "goodsAnalyze__top" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "货品出货统计", name: "1" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("dc-date-picker", {
            attrs: { label: "开始时间", type: "datetime" },
            model: {
              value: _vm.queryPara.startTime,
              callback: function ($$v) {
                _vm.$set(_vm.queryPara, "startTime", $$v)
              },
              expression: "queryPara.startTime",
            },
          }),
          _c("dc-date-picker", {
            attrs: { label: "结束时间", type: "datetime" },
            model: {
              value: _vm.queryPara.endTime,
              callback: function ($$v) {
                _vm.$set(_vm.queryPara, "endTime", $$v)
              },
              expression: "queryPara.endTime",
            },
          }),
          _c("dc-input", {
            attrs: { label: "SKU" },
            model: {
              value: _vm.queryPara.cSku,
              callback: function ($$v) {
                _vm.$set(_vm.queryPara, "cSku", $$v)
              },
              expression: "queryPara.cSku",
            },
          }),
          _c("dc-input", {
            attrs: { label: "条码" },
            model: {
              value: _vm.queryPara.barcode,
              callback: function ($$v) {
                _vm.$set(_vm.queryPara, "barcode", $$v)
              },
              expression: "queryPara.barcode",
            },
          }),
          _c("dc-input", {
            attrs: { label: "商品名称" },
            model: {
              value: _vm.queryPara.cName,
              callback: function ($$v) {
                _vm.$set(_vm.queryPara, "cName", $$v)
              },
              expression: "queryPara.cName",
            },
          }),
          _vm._v("   "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.queryPageStoreGoods },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "goodsAnalyze__body" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "marginTop10",
              attrs: { height: "450", data: _vm.goodsAnalyzeList },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "50" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "index",
                  label: "序号",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "sku",
                  label: "SKU",
                  width: "170",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "图片", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.c_master_url != null
                          ? _c("el-image", {
                              staticClass: "w50 h50",
                              attrs: {
                                "preview-src-list": [scope.row.c_master_url],
                                src: scope.row.c_master_url,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "货品条码",
                  prop: "barcode",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "货品名称",
                  prop: "cname",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "库存",
                  prop: "total",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "周出货",
                  prop: "weekNum",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "月出货",
                  prop: "monthNum",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "季出货",
                  prop: "seasonNum",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "半年出货",
                  prop: "halfYearNum",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "年出货",
                  prop: "yearNum",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "address",
                  label: "操作",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.goodsAnalyzeInfo(scope.row)
                              },
                            },
                          },
                          [_vm._v("出库分布")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.goodsAnalyzeSurplusInfo(scope.row)
                              },
                            },
                          },
                          [_vm._v("库存分布")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryPara.currentPage,
                  "page-size": _vm.queryPara.pageSize,
                  "page-sizes": _vm.pageSizes,
                  total: _vm.total,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "出库分布",
            visible: _vm.dialogVisible,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("goodsAnalyzeDialog", {
                attrs: { "goods-data": _vm.goodsData },
                on: { handleClose: _vm.handleCancel },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "库存分布",
            visible: _vm.dialogSurplusVisible,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSurplusVisible = $event
            },
          },
        },
        [
          _vm.dialogSurplusVisible
            ? _c("goodsAnalyzeSurplusDialog", {
                attrs: { "goods-data": _vm.goodsData },
                on: { handleClose: _vm.handleCancel },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }