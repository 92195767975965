<template>
  <!-- 配送分析-->
  <div class="deliveryData box-shadow">
    <div class="deliveryData__top">
      <div>
        <el-tabs v-model="tabs" @tab-click="handleClick">
          <el-tab-pane label="今日数据" name="1"></el-tab-pane>
          <el-tab-pane label="历史数据" name="2"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <dc-search-panel :params="queryPara" @search="doSearch" @reset="doReset">
      <dc-form-item field="rStore" label="全部门店">
        <el-select v-model="queryPara.rStore" placeholder="全部门店" @change="selectChange">
          <el-option v-for="item in storeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </dc-form-item>
      <dc-date-picker v-if="tabs === '2'" field="startTime" label="开始时间" value-format="yyyy-MM-dd"></dc-date-picker>
      <dc-date-picker v-if="tabs === '2'" field="endTime" label="结束时间" value-format="yyyy-MM-dd"></dc-date-picker>
      <el-button v-if="tabs === '2'" slot="btns" size="mini" type="primary" @click="getMonth(1)">本月</el-button>
      <el-button v-if="tabs === '2'" slot="btns" size="mini" type="primary" @click="getMonth(2)">上个月</el-button>
      <el-button v-if="tabs === '2'" slot="btns" size="mini" type="primary" @click="getMonth(3)">三个月</el-button>
    </dc-search-panel>
    <div class="deliveryData__body">
      <dc-datatable ref="list" :url="urlApi" :params="queryPara">
        <el-table-column align="center" label="配送平台">
          <template slot-scope="scope">
            {{ scope.row.zType | type }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="nOrderCount" label="发单数量"></el-table-column>
        <el-table-column align="center" prop="nDeliveryPrice" label="配送费"></el-table-column>
        <el-table-column align="center" prop="nAppendPrice" label="加小费"></el-table-column>
        <el-table-column align="center" prop="nSubtotal" label="小计"></el-table-column>
        <el-table-column align="center" prop="nUnitPrice" label="客单价"></el-table-column>
      </dc-datatable>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import baseUrls from "../../api/baseUrls";

export default {
  name: "DeliveryData",
  filters: {
    type(type) {
      let typeJson = {
        0: "顺丰",
        1: "达达",
        2: "美团配送",
        3: "蜂鸟配送",
        4: "闪送",
        5: "美团跑腿帮送",
        6: "美团跑腿",
        7: "快服务",
        8: "好急跑腿",
        9: "蜂鸟配送",
        10: "来答配送",
        11: "曹操送",
        12: "达达急送",
        13: "省点送",
        14: "蜂鸟跑腿",
        15: "自配送",
        16: "自提",
      };
      return typeJson[type];
    },
  },
  data() {
    return {
      urlApi: `${baseUrls.deliveryAnalysis}/queryPageList`,
      queryPara: {
        startTime: moment().format("YYYY-MM-DD"),
        endTime: moment().format("YYYY-MM-DD"),
        rStore: null,
      },
      tabs: "1",
      list: [],
      storeOptions: [
        { value: "1", label: "测试门店1" },
        { value: "2", label: "测试门店2" },
        { value: "3", label: "测试门店3" },
      ],
    };
  },
  mounted() {},
  methods: {
    doSearch() {
      this.$refs.list.load();
    },
    doReset() {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    handleClick(tab, event) {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      $table.resetHeight();
      this.doSearch();
    },
    getMonth(value) {
      if (value === 1) {
        this.queryPara.startTime = moment().startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().endOf("months").format("YYYY-MM-DD");
      } else if (value === 2) {
        this.queryPara.startTime = moment().subtract(1, "months").startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().subtract(1, "months").endOf("months").format("YYYY-MM-DD");
      } else if (value === 3) {
        this.queryPara.startTime = moment().subtract(3, "months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().format("YYYY-MM-DD");
      }
      this.doSearch();
    },
  },
};
</script>
<style lang="scss" scoped>
.deliveryData {
  padding: 10px 20px 0 20px;
  margin: 0 3px;
  height: 100%;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  &__body {
    overflow: hidden;
    flex-direction: column;
    display: flex;
    height: 100%;
    div {
      margin-bottom: 5px;
    }
  }
}
</style>
