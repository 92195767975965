<template>
  <div class="container">
    <div class="container__left box-shadow">
      <div class="data">
        <el-tabs v-model="tabs" class="wHP" tab-position="left" :stretch="true">
          <el-tab-pane v-for="tab in tapArray" :key="tab.component" :label="tab.menuName" :name="tab.component" />
        </el-tabs>
      </div>
    </div>
    <div class="container__right borderRadius5">
      <businessData v-if="tabs === 'tradeOverview'" />
      <shopData v-if="tabs === 'storeCount'" />
      <deliveryData v-if="tabs === 'deliveryAnalysis'" />
      <trenchData v-if="tabs === 'channelAnalysis'" />
      <goodsAnalyze v-if="tabs === 'goodsAnalysis'" />
    </div>
  </div>
</template>
<script>
import businessData from "./businessData";
import deliveryData from "./deliveryData";
import goodsAnalyze from "./goodsAnalyze";
import shopData from "./shopData";
import trenchData from "./trenchData";
import store from "../../store";
export default {
  name: "Index",
  components: {
    businessData,
    deliveryData,
    goodsAnalyze,
    shopData,
    trenchData,
  },
  data() {
    return {
      queryPara: {},
      like: true,
      index: 0,
      tabs: "",
      tapArray: "",
    };
  },
  created() {
    const nav = JSON.parse(JSON.stringify(store.getters.navs));
    const result = nav.find((item) => item.component === "dataIndex");
    this.tapArray = result.children;
    this.tabs = this.tapArray[0].component;
  },
  mounted() {},
  methods: {
    change(value) {
      this.index = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  width: 100%;
  background-color: #f6f6f6;
  padding: 10px;
  flex-direction: row;
  overflow: hidden;
  z-index: 1;
  &__left {
    width: 15%;
    height: 100%;
    margin: 0 15px 0 3px;
    margin-bottom: 10px;
    background-color: white;
  }
  &__right {
    height: 100%;
    flex: 1;
  }
}
</style>

<style lang="scss">
.data {
  height: 100%;
  .el-tabs {
    height: 100%;
    width: 100%;
  }
  .el-tabs__header {
    width: 100%;
  }
  .el-tabs--left .el-tabs__item.is-left {
    text-align: left;
    height: 100%;
  }
}
</style>
