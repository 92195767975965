var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "container__left box-shadow" }, [
      _c(
        "div",
        { staticClass: "data" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "wHP",
              attrs: { "tab-position": "left", stretch: true },
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            _vm._l(_vm.tapArray, function (tab) {
              return _c("el-tab-pane", {
                key: tab.component,
                attrs: { label: tab.menuName, name: tab.component },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "container__right borderRadius5" },
      [
        _vm.tabs === "tradeOverview" ? _c("businessData") : _vm._e(),
        _vm.tabs === "storeCount" ? _c("shopData") : _vm._e(),
        _vm.tabs === "deliveryAnalysis" ? _c("deliveryData") : _vm._e(),
        _vm.tabs === "channelAnalysis" ? _c("trenchData") : _vm._e(),
        _vm.tabs === "goodsAnalysis" ? _c("goodsAnalyze") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }