var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ReviewInventoryIn" },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "商品SKU" } }, [
            _vm._v(_vm._s(_vm.goodsData.sku)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "货品条码" } }, [
            _vm._v(_vm._s(_vm.goodsData.barcode)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "货品类别" } }),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "货品名称" } }, [
            _vm._v(_vm._s(_vm.goodsData.cname)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "货品成本价" } }),
          _c("el-descriptions-item", { attrs: { label: "货品零售价" } }, [
            _vm._v(_vm._s(_vm.goodsData.price)),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "h500" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: { data: _vm.orderDetailOutList, height: "450" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  align: "center",
                  width: "50",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "门店名称",
                  align: "center",
                  width: "70",
                  prop: "s_store",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "出库单号",
                  align: "center",
                  width: "70",
                  prop: "c_sys",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库位号",
                  align: "center",
                  width: "70",
                  prop: "c_warehouse",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库存",
                  align: "center",
                  width: "70",
                  prop: "n_total",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "出库量",
                  align: "center",
                  width: "70",
                  prop: "n_out",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "制单人",
                  align: "center",
                  width: "70",
                  prop: "s_create",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "审核人",
                  align: "center",
                  width: "70",
                  prop: "s_audit",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "出库时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm._f("time")(scope.row.t_out))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryPara.currentPage,
                  "page-size": _vm.queryPara.pageSize,
                  "page-sizes": _vm.pageSizes,
                  total: _vm.total,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex alignItemsCenter",
          staticStyle: { "justify-content": "space-between", flex: "1" },
        },
        [
          _c("div"),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.handleExport } },
                [_vm._v("导出Excel")]
              ),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }