<template>
  <!-- 渠道分析-->
  <div class="trenchData box-shadow">
    <div class="trenchData__top">
      <div>
        <el-tabs v-model="tabs" @tab-click="handleClick">
          <el-tab-pane label="今日数据" name="1"></el-tab-pane>
          <el-tab-pane label="历史数据" name="2"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <dc-search-panel :params="queryPara" @search="doSearch" @reset="doReset">
      <dc-form-item field="rStore" label="全部门店">
        <el-select v-model="queryPara.rStore" placeholder="全部门店" @change="selectChange">
          <el-option v-for="item in storeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </dc-form-item>
      <dc-date-picker v-if="tabs === '2'" field="startTime" label="开始时间" value-format="yyyy-MM-dd"></dc-date-picker>
      <dc-date-picker v-if="tabs === '2'" field="endTime" label="结束时间" value-format="yyyy-MM-dd"></dc-date-picker>
      <el-button v-if="tabs === '2'" slot="btns" size="mini" type="primary" @click="getMonth(1)">本月</el-button>
      <el-button v-if="tabs === '2'" slot="btns" size="mini" type="primary" @click="getMonth(2)">上个月</el-button>
      <el-button v-if="tabs === '2'" slot="btns" size="mini" type="primary" @click="getMonth(3)">三个月</el-button>
    </dc-search-panel>
    <div class="trenchData__body">
      <dc-datatable ref="list" :url="urlApi" :params="queryPara">
        <el-table-column label="平台" align="center">
          <template slot-scope="scope">
            {{ scope.row.zType | type }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="nValid" label="有效订单"></el-table-column>
        <el-table-column align="center" prop="nMerchantRevenue" label="商家实收"></el-table-column>
        <el-table-column align="center" prop="nUnitPrice" label="客单价"></el-table-column>
      </dc-datatable>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "TrenchData",
  filters: {
    type(type) {
      if (type === 1) return "美团闪购";
      if (type === 2) return "饿了么零售";
      if (type === 3) return "京东到家";
      if (type === 4) return "美团外卖";
      return "";
    },
  },
  data() {
    return {
      urlApi: `${this.baseUrls.channelAnalysis}/queryPageList`,
      queryPara: {
        startTime: moment().format("YYYY-MM-DD"),
        endTime: moment().format("YYYY-MM-DD"),
      },
      tabs: "1",
      trenchList: [],
      storeOptions: [
        { value: "1", label: "测试门店1" },
        { value: "2", label: "测试门店2" },
        { value: "3", label: "测试门店3" },
      ],
    };
  },
  mounted() {},
  methods: {
    doSearch() {
      this.$refs.list.load();
    },
    doReset() {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    handleClick(tab, event) {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      $table.resetHeight();
      this.doSearch();
    },
    getMonth(value) {
      if (value === 1) {
        this.queryPara.startTime = moment().startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().endOf("months").format("YYYY-MM-DD");
      } else if (value === 2) {
        this.queryPara.startTime = moment().subtract(1, "months").startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().subtract(1, "months").endOf("months").format("YYYY-MM-DD");
      } else if (value === 3) {
        this.queryPara.startTime = moment().subtract(3, "months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().format("YYYY-MM-DD");
      }
      this.doSearch();
    },
  },
};
</script>
<style lang="scss" scoped>
.trenchData {
  padding: 10px 20px 0 20px;
  margin: 0 3px;
  height: 100%;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  &__body {
    overflow: hidden;
    flex-direction: column;
    display: flex;
    height: 100%;
    div {
      margin-bottom: 5px;
    }
  }
}
</style>
