<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- 营业概览-->
  <div class="businessData">
    <div class="businessData__top box-shadow">
      <div class="title">
        <div class="fs20 fWB">今日实时数据<span class="fs13 purple">（同比数据为昨日同时段的数据变化）</span></div>
      </div>
      <div class="data">
        <div>
          <div class="fs18">商家订单实收</div>
          <div class="fs20">{{ todayData.nMerchantRevenue }}</div>
          <div class="fs12" v-html="getStatus(todayData.nMerchantRevenue, yesterdayData.nMerchantRevenue)"></div>
        </div>
        <div>
          <div class="fs18">有效订单总数</div>
          <div class="fs20">{{ todayData.nValidTotal }}</div>
          <div class="fs12" v-html="getStatus(todayData.nValidTotal, yesterdayData.nValidTotal)"></div>
        </div>
        <div>
          <div class="fs18">完成订单总数</div>
          <div class="fs20">{{ todayData.nFinishCount }}</div>
          <div class="fs12" v-html="getStatus(todayData.nFinishCount, yesterdayData.nFinishCount)"></div>
        </div>
        <div>
          <div class="fs18">进行中订单</div>
          <div class="fs20">{{ todayData.nSustainCount }}</div>
          <div class="fs12" v-html="getStatus(todayData.nSustainCount, yesterdayData.nSustainCount)"></div>
        </div>
        <div>
          <div class="fs18">已取消订单量</div>
          <div class="fs20">{{ todayData.nSustainCount }}</div>
          <div class="fs12" v-html="getStatus(todayData.nSustainCount, yesterdayData.nSustainCount)"></div>
        </div>
        <div>
          <div class="fs18">单均价</div>
          <div class="fs20">{{ todayData.nAvgPrice }}</div>
          <div class="fs12" v-html="getStatus(todayData.nAvgPrice, yesterdayData.nAvgPrice)"></div>
        </div>
      </div>
    </div>
    <div class="businessData__body box-shadow scrollbar">
      <div class="title">
        <div class="fs20 fWB">历史营业数据<span class="fs13 purple">（由于平台结算数据未及时同步，数据可能略有差异）</span></div>
      </div>
      <dc-search-panel :params="queryPara" @search="doSearch" @reset="doReset">
        <dc-form-item field="rStore" label="全部门店">
          <el-select v-model="queryPara.rStore" placeholder="全部门店" @change="selectChange">
            <el-option v-for="item in storeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </dc-form-item>
        <dc-date-picker field="startTime" label="开始时间" value-format="yyyy-MM-dd"></dc-date-picker>
        <dc-date-picker field="endTime" label="结束时间" value-format="yyyy-MM-dd"></dc-date-picker>
        <el-button slot="btns" size="mini" type="primary" @click="getMonth(1)">本月</el-button>
        <el-button slot="btns" size="mini" type="primary" @click="getMonth(2)">上个月</el-button>
        <el-button slot="btns" size="mini" type="primary" @click="getMonth(3)">三个月</el-button>
      </dc-search-panel>
      <div class="data">
        <el-tabs v-model="tabs">
          <el-tab-pane label="有效订单" name="1" />
          <el-tab-pane label="无效订单" name="2" />
          <el-tab-pane label="营业额" name="3" />
          <el-tab-pane label="预计收入" name="4" />
        </el-tabs>
        <div id="myChart"></div>
      </div>
    </div>
  </div>
  <!--eslint-enable-->
</template>
<script>
import moment from "moment";

export default {
  name: "BusinessData",
  filters: {},
  data() {
    return {
      queryPara: {
        startTime: moment().startOf("months").format("YYYY-MM-DD"),
        endTime: moment().endOf("months").format("YYYY-MM-DD"),
        rStore: null,
      },
      tabs: "1",
      todayData: {
        nMerchantRevenue: 0,
        nValidTotal: 0,
        nFinishCount: 0,
        nSustainCount: 0,
        nCancelCount: 0,
        nAvgPrice: 0,
      },
      yesterdayData: {
        nMerchantRevenue: 0,
        nValidTotal: 0,
        nFinishCount: 0,
        nSustainCount: 0,
        nCancelCount: 0,
        nAvgPrice: 0,
      },
      businessData: [],
      storeOptions: [
        { value: "0", label: "全部门店" },
        { value: "1", label: "测试门店1" },
        { value: "2", label: "测试门店2" },
        { value: "3", label: "测试门店3" },
      ],
      echartsOption: {
        // echarts选项，所有绘图数据和样式都在这里设置
        xAxis: {
          type: "category",
          data: [], // x轴数据
          name: "日期", // x轴名称
          nameTextStyle: {
            // x轴名称样式
            fontWeight: 600,
            fontSize: 18,
          },
        },
        yAxis: {
          type: "value",
          name: "", // y轴名称
          nameTextStyle: {
            // y轴名称样式
            fontWeight: 600,
            fontSize: 18,
          },
        },
        tooltip: {
          //鼠标放到图上的数据展示样式
          trigger: "axis",
        },
        series: [
          //每条折线的数据系列
          {
            data: [],
            type: "line",
          },
        ],
      },
    };
  },
  created() {
    this.queryListForToday();
  },
  mounted() {},
  methods: {
    queryListForToday() {
      let startTime = moment().add(-1, "day").format("YYYY-MM-DD");
      let endTime = moment().format("YYYY-MM-DD");
      let url = `${this.baseUrls.tradeOverview}/queryList`;
      let params = {
        startTime: startTime,
        endTime: endTime,
      };
      this.topsAjax.get(url, params).then((response) => {
        response.forEach((p) => {
          if (p.tDay === startTime) this.yesterdayData = p;
          if (p.tDay === endTime) this.todayData = p;
        });
      });
    },
    queryList() {
      let url = `${this.baseUrls.tradeOverview}/queryList`;
      let params = this.queryPara;
      this.topsAjax.get(url, params).then((response) => {
        response.forEach((p) => {
          this.businessData = p;
        });
      });
    },
    doSearch() {
      this.queryListForToday();
      this.queryList();
    },
    doReset() {
      this.queryPara.startTime = moment().startOf("months").format("YYYY-MM-DD");
      this.queryPara.endTime = moment().endOf("months").format("YYYY-MM-DD");
      this.doSearch();
    },
    getMonth(value) {
      if (value === 1) {
        this.queryPara.startTime = moment().startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().endOf("months").format("YYYY-MM-DD");
      } else if (value === 2) {
        this.queryPara.startTime = moment().subtract(1, "months").startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().subtract(1, "months").endOf("months").format("YYYY-MM-DD");
      } else if (value === 3) {
        this.queryPara.startTime = moment().subtract(3, "months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().format("YYYY-MM-DD");
      }
      this.doSearch();
    },
    getStatus(p1, p2) {
      if (p1 > p2) {
        return `与昨日相比<span style="color: red">⬆${p1 - p2}`;
      } else if (p1 < p2) {
        return `与昨日相比<span style="color: green">⬇${p2 - p1}`;
      } else {
        return `与昨日相比 持平`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.businessData {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &__top {
    //height: 300px;
    margin: 0 3px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: white;

    flex-direction: column;
    display: flex;

    .title {
      margin-bottom: 10px;
    }

    .data {
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;

      & > div {
        flex: 1;
        height: 100px;
        padding: 15px;
        display: block;
        width: 140px;
        text-align: center;
      }
    }
  }

  &__body {
    height: 100%;
    background-color: white;
    padding: 10px;
    flex-direction: column;
    display: flex;
    flex: 1;
    margin: 0 3px;

    .title {
      margin-bottom: 10px;

      div {
        margin-bottom: 10px;

        * {
          margin-right: 10px;
        }
      }
    }

    .data {
      height: 100%;
    }
  }
}

#myChart {
  width: 100%;
  height: 500px;
}
</style>
