var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "businessData" }, [
    _c("div", { staticClass: "businessData__top box-shadow" }, [
      _vm._m(0),
      _c("div", { staticClass: "data" }, [
        _c("div", [
          _c("div", { staticClass: "fs18" }, [_vm._v("商家订单实收")]),
          _c("div", { staticClass: "fs20" }, [
            _vm._v(_vm._s(_vm.todayData.nMerchantRevenue)),
          ]),
          _c("div", {
            staticClass: "fs12",
            domProps: {
              innerHTML: _vm._s(
                _vm.getStatus(
                  _vm.todayData.nMerchantRevenue,
                  _vm.yesterdayData.nMerchantRevenue
                )
              ),
            },
          }),
        ]),
        _c("div", [
          _c("div", { staticClass: "fs18" }, [_vm._v("有效订单总数")]),
          _c("div", { staticClass: "fs20" }, [
            _vm._v(_vm._s(_vm.todayData.nValidTotal)),
          ]),
          _c("div", {
            staticClass: "fs12",
            domProps: {
              innerHTML: _vm._s(
                _vm.getStatus(
                  _vm.todayData.nValidTotal,
                  _vm.yesterdayData.nValidTotal
                )
              ),
            },
          }),
        ]),
        _c("div", [
          _c("div", { staticClass: "fs18" }, [_vm._v("完成订单总数")]),
          _c("div", { staticClass: "fs20" }, [
            _vm._v(_vm._s(_vm.todayData.nFinishCount)),
          ]),
          _c("div", {
            staticClass: "fs12",
            domProps: {
              innerHTML: _vm._s(
                _vm.getStatus(
                  _vm.todayData.nFinishCount,
                  _vm.yesterdayData.nFinishCount
                )
              ),
            },
          }),
        ]),
        _c("div", [
          _c("div", { staticClass: "fs18" }, [_vm._v("进行中订单")]),
          _c("div", { staticClass: "fs20" }, [
            _vm._v(_vm._s(_vm.todayData.nSustainCount)),
          ]),
          _c("div", {
            staticClass: "fs12",
            domProps: {
              innerHTML: _vm._s(
                _vm.getStatus(
                  _vm.todayData.nSustainCount,
                  _vm.yesterdayData.nSustainCount
                )
              ),
            },
          }),
        ]),
        _c("div", [
          _c("div", { staticClass: "fs18" }, [_vm._v("已取消订单量")]),
          _c("div", { staticClass: "fs20" }, [
            _vm._v(_vm._s(_vm.todayData.nSustainCount)),
          ]),
          _c("div", {
            staticClass: "fs12",
            domProps: {
              innerHTML: _vm._s(
                _vm.getStatus(
                  _vm.todayData.nSustainCount,
                  _vm.yesterdayData.nSustainCount
                )
              ),
            },
          }),
        ]),
        _c("div", [
          _c("div", { staticClass: "fs18" }, [_vm._v("单均价")]),
          _c("div", { staticClass: "fs20" }, [
            _vm._v(_vm._s(_vm.todayData.nAvgPrice)),
          ]),
          _c("div", {
            staticClass: "fs12",
            domProps: {
              innerHTML: _vm._s(
                _vm.getStatus(
                  _vm.todayData.nAvgPrice,
                  _vm.yesterdayData.nAvgPrice
                )
              ),
            },
          }),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "businessData__body box-shadow scrollbar" },
      [
        _vm._m(1),
        _c(
          "dc-search-panel",
          {
            attrs: { params: _vm.queryPara },
            on: { search: _vm.doSearch, reset: _vm.doReset },
          },
          [
            _c(
              "dc-form-item",
              { attrs: { field: "rStore", label: "全部门店" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "全部门店" },
                    on: { change: _vm.selectChange },
                    model: {
                      value: _vm.queryPara.rStore,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryPara, "rStore", $$v)
                      },
                      expression: "queryPara.rStore",
                    },
                  },
                  _vm._l(_vm.storeOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c("dc-date-picker", {
              attrs: {
                field: "startTime",
                label: "开始时间",
                "value-format": "yyyy-MM-dd",
              },
            }),
            _c("dc-date-picker", {
              attrs: {
                field: "endTime",
                label: "结束时间",
                "value-format": "yyyy-MM-dd",
              },
            }),
            _c(
              "el-button",
              {
                attrs: { slot: "btns", size: "mini", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.getMonth(1)
                  },
                },
                slot: "btns",
              },
              [_vm._v("本月")]
            ),
            _c(
              "el-button",
              {
                attrs: { slot: "btns", size: "mini", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.getMonth(2)
                  },
                },
                slot: "btns",
              },
              [_vm._v("上个月")]
            ),
            _c(
              "el-button",
              {
                attrs: { slot: "btns", size: "mini", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.getMonth(3)
                  },
                },
                slot: "btns",
              },
              [_vm._v("三个月")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.tabs,
                  callback: function ($$v) {
                    _vm.tabs = $$v
                  },
                  expression: "tabs",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "有效订单", name: "1" } }),
                _c("el-tab-pane", { attrs: { label: "无效订单", name: "2" } }),
                _c("el-tab-pane", { attrs: { label: "营业额", name: "3" } }),
                _c("el-tab-pane", { attrs: { label: "预计收入", name: "4" } }),
              ],
              1
            ),
            _c("div", { attrs: { id: "myChart" } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "fs20 fWB" }, [
        _vm._v("今日实时数据"),
        _c("span", { staticClass: "fs13 purple" }, [
          _vm._v("（同比数据为昨日同时段的数据变化）"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "fs20 fWB" }, [
        _vm._v("历史营业数据"),
        _c("span", { staticClass: "fs13 purple" }, [
          _vm._v("（由于平台结算数据未及时同步，数据可能略有差异）"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }