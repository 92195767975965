<template>
  <!-- 商品分析-->
  <div class="goodsAnalyze box-shadow">
    <div class="goodsAnalyze__top">
      <el-tabs v-model="tabs">
        <el-tab-pane label="货品出货统计" name="1"></el-tab-pane>
        <!-- <el-tab-pane label="历史数据" name="2"></el-tab-pane> -->
      </el-tabs>
    </div>
    <div>
      <dc-date-picker v-model="queryPara.startTime" label="开始时间" type="datetime"> </dc-date-picker>
      <dc-date-picker v-model="queryPara.endTime" label="结束时间" type="datetime"> </dc-date-picker>
      <dc-input v-model="queryPara.cSku" label="SKU" />
      <dc-input v-model="queryPara.barcode" label="条码" />
      <dc-input v-model="queryPara.cName" label="商品名称" />
      &nbsp;&nbsp;<el-button  type="primary" @click="queryPageStoreGoods">查询</el-button>
      <el-button   type="primary" @click="handleReset">重置</el-button>
    </div>
    <div class="goodsAnalyze__body">
      <el-table v-loading="loading" class="marginTop10" height="450" :data="goodsAnalyzeList">
        <el-table-column align="center" type="selection" width="50" />
        <el-table-column align="center" type="index" label="序号" width="50" />
        <el-table-column align="center" prop="sku" label="SKU" width="170" sortable/>
        <el-table-column align="center" label="图片" width="70">
          <template #default="scope">
            <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="货品条码" prop="barcode" sortable/>
        <el-table-column align="center" label="货品名称" prop="cname" sortable/>
        <el-table-column align="center" label="库存" prop="total" sortable/>
        <el-table-column align="center" label="周出货" prop="weekNum" sortable/>
        <el-table-column align="center" label="月出货" prop="monthNum" sortable/>
        <el-table-column align="center" label="季出货" prop="seasonNum" sortable/>
        <el-table-column align="center" label="半年出货" prop="halfYearNum" sortable/>
        <el-table-column align="center" label="年出货" prop="yearNum" sortable/>
        <el-table-column align="center" prop="address" label="操作" width="140">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="goodsAnalyzeInfo(scope.row)">出库分布</el-button>
            <el-button type="text" size="mini" @click="goodsAnalyzeSurplusInfo(scope.row)">库存分布</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination :current-page="queryPara.currentPage" :page-size="queryPara.pageSize" :page-sizes="pageSizes" :total="total" layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
      </div>
    </div>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="出库分布" :visible.sync="dialogVisible" width="800px" append-to-body>
      <goodsAnalyzeDialog v-if="dialogVisible" :goods-data="goodsData" @handleClose="handleCancel" />
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="库存分布" :visible.sync="dialogSurplusVisible" width="800px" append-to-body>
      <goodsAnalyzeSurplusDialog v-if="dialogSurplusVisible" :goods-data="goodsData" @handleClose="handleCancel" />
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import goodsAnalyzeDialog from "@/views/data/dialog/goodsAnalyzeDialog.vue";
import goodsAnalyzeSurplusDialog from "@/views/data/dialog/goodsAnalyzeSurplusDialog.vue";
export default {
  name: "GoodsAnalyze",
  components: {
    goodsAnalyzeDialog,
    goodsAnalyzeSurplusDialog
  },
  data() {
    return {
      queryPara: {
        currentPage: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
      },
      total: 0,
      goodsData: null,
      dialogVisible: false,
      dialogSurplusVisible: false,
      tabs: "1",
      goodsAnalyzeList: [],
      loading: false,
      pageSizes: [10, 20, 30, 50, 100],
      storeOptions: [
        { value: "0", label: "全部门店" },
        { value: "1", label: "测试门店1" },
        { value: "2", label: "测试门店2" },
        { value: "3", label: "测试门店3" },
      ],
    };
  },
  mounted() {
    this.queryPageStoreGoods()
  },
  methods: {
    queryPageStoreGoods() {
      this.loading = true
      let url = `${this.baseUrls.goods}/queryPageOutList`;
      let params = this.queryPara;
      this.topsAjax.get(url, params).then((res) => {
        this.loading = false
        this.total = res.total;
        this.goodsAnalyzeList = res.list;
      }).catch(() => {
        this.loading = false
      });
    },
    handleReset() {
      this.queryPara = {
        currentPage: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
      }
    },
    goodsAnalyzeInfo(row) {
      this.goodsData = row
      this.dialogVisible = true
    },
    goodsAnalyzeSurplusInfo(row) {
      this.goodsData = row
      this.dialogSurplusVisible = true
    },
    handleCurrentChange(val) {
      this.queryPara.currentPage = val;
      this.queryPageStoreGoods();
    },
    handleSizeChange(val) {
      this.queryPara.pageSize = val;
      this.queryPageStoreGoods();
    },
    handleCancel() {
      this.dialogVisible = false
      this.dialogSurplusVisible = false
    }
  },
};
</script>
<style lang="scss" scoped>
.goodsAnalyze {
  padding: 10px 20px 0 20px;
  margin: 0 3px;
  height: 100%;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  &__body {
    overflow: hidden;
    flex-direction: column;
    display: flex;
    height: 100%;
    div {
      margin-bottom: 5px;
    }
  }
}
</style>
