<template>
  <!-- 门店统计-->
  <div class="shopData box-shadow">
    <div class="shopData__top">
      <el-tabs v-model="tabs" @tab-click="handleClick">
        <el-tab-pane label="今日数据" name="1"></el-tab-pane>
        <el-tab-pane label="历史数据" name="2"></el-tab-pane>
      </el-tabs>
    </div>
    <dc-search-panel v-if="tabs === '2'" :params="queryPara" @search="doSearch" @reset="doReset">
      <dc-date-picker field="startTime" label="开始时间" value-format="yyyy-MM-dd"></dc-date-picker>
      <dc-date-picker field="endTime" label="结束时间" value-format="yyyy-MM-dd"></dc-date-picker>
      <el-button slot="btns" size="mini" type="primary" @click="getMonth(1)">本月</el-button>
      <el-button slot="btns" size="mini" type="primary" @click="getMonth(2)">上个月</el-button>
      <el-button slot="btns" size="mini" type="primary" @click="getMonth(3)">三个月</el-button>
    </dc-search-panel>
    <div class="shopData__body">
      <el-table :data="storeCountList" border show-summary :summary-method="getSummaries">
        <el-table-column align="center" fixed prop="cName" label="门店"></el-table-column>
        <el-table-column align="center" prop="nValid" label="有效订单"></el-table-column>
        <el-table-column align="center" prop="nTurnover" label="营业额"></el-table-column>
        <el-table-column align="center" prop="nMerchantRevenue" label="商家实收"></el-table-column>
        <el-table-column align="center" prop="nUnitPrice" label="客单价"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import moment from "moment";
import baseUrls from "../../api/baseUrls";

export default {
  name: "ShopData",
  data() {
    return {
      queryPara: {
        startTime: moment().format("YYYY-MM-DD"),
        endTime: moment().format("YYYY-MM-DD"),
      },
      tabs: "1",
      storeCountList: [],
    };
  },
  mounted() {},
  created() {
    this.queryList();
  },
  methods: {
    queryList() {
      let url = `${baseUrls.storeCount}/queryList`;
      let params = this.queryPara;
      this.topsAjax.get(url, params).then((response) => {
        this.storeCountList = response;
      });
    },
    doSearch() {
      this.queryList();
    },
    doReset() {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    handleClick(tab, event) {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    getMonth(value) {
      if (value === 1) {
        this.queryPara.startTime = moment().startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().endOf("months").format("YYYY-MM-DD");
      } else if (value === 2) {
        this.queryPara.startTime = moment().subtract(1, "months").startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().subtract(1, "months").endOf("months").format("YYYY-MM-DD");
      } else if (value === 3) {
        this.queryPara.startTime = moment().subtract(3, "months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().format("YYYY-MM-DD");
      }
      this.doSearch();
    },
    getSummaries(param) {
      //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
      const { columns, data } = param;
      const len = columns.length;
      const sums = [];
      columns.forEach((column, index) => {
        //如果是第一列，则最后一行展示为“总计”两个字
        if (index === 0) {
          sums[index] = "总计";
          //如果是最后一列，索引为列数-1，则显示计算总和
        } else {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = "N/A";
          }
          //如果是除了第一列和最后一列的其他列，则显示为空
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
.shopData {
  padding: 10px 20px 0 20px;
  margin: 0 3px;
  height: 100%;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>
